import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-counter',
	templateUrl: './counter.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule],
})
export class CounterComponent {
	@Input() counter = 0;
	@Input() options: number[] = [];
	@Input() rowName = '';
	@Output() counterChange: EventEmitter<number> = new EventEmitter<number>();

	operation(event: Event, value: number, options: number[], operation: 1 | -1, isForbidden: boolean): void {
		if (isForbidden) {
			return;
		}
		event.stopPropagation();
		event.preventDefault();
		const index = options.findIndex((o) => o === value);
		this.counterChange.emit(options[index + operation]);
	}
}
