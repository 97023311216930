/* eslint @typescript-eslint/explicit-module-boundary-types: 0 */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OffersState } from '@/_store/offers/offers.reducer';
import {
	Nights,
	OfferListHeaderItemMode,
	Participant,
	Row,
	TopSearchDropdownItemMode,
} from '@/new-top-search/top-search-dropdown-item/top-search-dropdown-item.types';
import { DateRange } from '@angular/material/datepicker';
import { createOfferListApiParams, createOfferListHeaderArray, findHeaderValue } from '@/_store/offers/offers.utils';
import { replacePolishLetters } from '@/_utils/strings/replace-polish-letters';

const selectOffersFeature = createFeatureSelector<OffersState>('offers');

export const selectFilterOptions = createSelector(selectOffersFeature, (state) => state.filterOptions);

export const selectOfferList = createSelector(selectOffersFeature, (state) => state.offersList);

export const selectBoardOptions = createSelector(selectOffersFeature, (state) => state.filterOptions.boards);

export const selectStopOptions = createSelector(selectOffersFeature, (state) => state.filterOptions.stops);

export const selectLocationOptions = createSelector(selectOffersFeature, (state) => state.filterOptions.locations);

export const selectKindOptions = createSelector(selectOffersFeature, (state) => state.filterOptions.kinds);

export const selectConvenienceOptions = createSelector(
	selectOffersFeature,
	(state) => state.filterOptions.conveniences,
);

export const selectTagOptions = createSelector(selectOffersFeature, (state) => state.filterOptions.tags);

export const selectOffersDateRange = createSelector(selectFilterOptions, (options) => {
	const { lower, upper } = options.offersDateRange;
	return new DateRange(new Date(lower), new Date(upper));
});

export const selectSelectedDateRange = createSelector(selectOffersFeature, (state) => {
	const { lower, upper } = state.selected.offersDateRange;
	return new DateRange(new Date(lower), new Date(upper));
});

export const selectAvailableBoardsDialog = createSelector(selectOffersFeature, (state) => state.availableBoardsDialog);

export const selectParticipantsRows = createSelector(selectFilterOptions, (options): Row[] => [
	{
		rowName: Participant.ADULTS,
		options: options.adults,
	},
	{
		rowName: Participant.CHILDREN,
		options: options.children,
	},
]);

export const selectNightsRows = createSelector(selectFilterOptions, (options): Row[] => [
	{
		options: options.nights.min,
		rowName: Nights.FROM,
	},
	{
		options: options.nights.max,
		rowName: Nights.TO,
	},
]);

export const selectParticipantCounters = createSelector(
	selectOffersFeature,
	selectParticipantsRows,
	(state, participants) => {
		if (!participants.every((participant) => participant.options.length)) {
			return {};
		}
		return {
			[Participant.ADULTS]: state.selected.adults,
			[Participant.CHILDREN]: state.selected.children,
		};
	},
);

export const selectNightCounters = createSelector(selectOffersFeature, selectNightsRows, (state, nights) => {
	if (!nights.every((night) => night.options.length)) {
		return {};
	}
	return {
		[Nights.FROM]: state.selected.nights.min,
		[Nights.TO]: state.selected.nights.max,
	};
});

export const selectOptions = (mode: TopSearchDropdownItemMode) =>
	createSelector(selectFilterOptions, (options) => {
		switch (mode) {
			case TopSearchDropdownItemMode.LOCATIONS:
				return options.locations;
			case TopSearchDropdownItemMode.NIGHTS:
			case TopSearchDropdownItemMode.PARTICIPANTS:
			case TopSearchDropdownItemMode.DATE_RANGE:
			case TopSearchDropdownItemMode.SEARCH:
				return;
			case TopSearchDropdownItemMode.BOARDS:
				return options.boards;
			case TopSearchDropdownItemMode.KINDS:
				return options.kinds;
			case TopSearchDropdownItemMode.CONVENIENCES:
				return options.conveniences;
			case TopSearchDropdownItemMode.STOPS:
				return options.stops;
			case TopSearchDropdownItemMode.TAGS:
				return options.tags;
			default:
				const value: never = mode;
				throw new Error(`There is no option like ${value}`);
		}
	});

export const selectSelectedOptionsParams = createSelector(selectOffersFeature, (state) =>
	createOfferListApiParams(state.selected),
);

export const selectSelectedValues = createSelector(selectOffersFeature, (state) => state.selected);

export const selectOfferListHeader = createSelector(selectSelectedValues, selectFilterOptions, (selected, options) =>
	createOfferListHeaderArray(selected, options),
);

export const selectOfferListHeaderByKey = (key: string) =>
	createSelector(selectOfferListHeader, (headers) => {
		let headerValue = findHeaderValue(headers, key) || null;

		if (key === TopSearchDropdownItemMode.PARTICIPANTS) {
			const adults = findHeaderValue(headers, OfferListHeaderItemMode.ADULTS);
			const children = findHeaderValue(headers, OfferListHeaderItemMode.CHILDREN);

			headerValue = adults ? adults.toString() : 0;
			headerValue += children ? ` + ${children}` : '';
		}

		return headerValue;
	});

export const selectOfferListCount = createSelector(selectOffersFeature, (state) => state.count);

export const selectOwnTransport = createSelector(selectSelectedValues, (selected) => selected.ownTransport);

export const selectIsOptionsAreEmpty = createSelector(selectFilterOptions, (options) =>
	Object.values(options)
		.map((option) => {
			if (Array.isArray(option)) {
				return option;
			}
			return [];
		})
		.every((v) => !v.length),
);

export const selectSorting = createSelector(selectOffersFeature, (state) => state.sorting);

export const selectOffersOffset = createSelector(selectOffersFeature, (state) => state.offset);

export const selectNextOffers = createSelector(selectOffersFeature, (state) => state.nextPage);

export const selectNextPageIsLoading = createSelector(selectOffersFeature, (state) => state.nextPageIsLoading);

export const searchFilterOptions = (searchTerm: string) =>
	createSelector(selectFilterOptions, (state) => ({
		locations: state.locations.filter((location) =>
			replacePolishLetters(location.name.toLocaleLowerCase()).includes(
				searchTerm ? replacePolishLetters(searchTerm.toLocaleLowerCase()) : null,
			),
		),
		products: state.products.filter((product) =>
			replacePolishLetters(product.name.toLocaleLowerCase()).includes(
				searchTerm ? replacePolishLetters(searchTerm.toLocaleLowerCase()) : null,
			),
		),
	}));
