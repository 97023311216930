import { createAction, props } from '@ngrx/store';
import {
	NumericDictionary,
	OfferDateRange,
	OfferFilter,
	OfferList,
	Selected,
	SORTING_OPTIONS,
} from '@/_store/offers/offers.types';
import { RowValue } from '@/new-top-search/top-search-dropdown-item/top-search-dropdown-item.types';

const KEY = '[OFFERS]';

// FETCHES
export const fetchOfferFilter = createAction(`${KEY} fetch offer filter`);
export const fetchOfferFilterSuccess = createAction(
	`${KEY} fetch offer filter success`,
	props<{ filterOptions: OfferFilter }>(),
);
export const fetchOfferFilterError = createAction(`${KEY} offer filter error`, props<{ error: Error }>());

export const fetchOffers = createAction(
	`${KEY} fetch offer list`,
	props<{ params: string; paramsForNextPage: string }>(),
);

export const fetchOffersSuccess = createAction(
	`${KEY} fetch offer list success`,
	props<{ offersList: NumericDictionary<OfferList> }>(),
);

export const fetchOffersError = createAction(`${KEY} offer list error`, props<{ error: Error }>());

export const fetchBoards = createAction(`${KEY} fetch boards`, props<{ id: number }>());

export const fetchBoardsSuccess = createAction(
	`${KEY} fetch boards success`,
	props<{ boards: { [key: string]: string } }>(),
);

export const fetchBoardsError = createAction(`${KEY} boards error`, props<{ error: Error }>());

export const fetchNextOffers = createAction(`${KEY} next fetch offer list`, props<{ params: string }>());

export const fetchNextOffersSuccess = createAction(
	`${KEY} next offer list success`,
	props<{ nextPage: NumericDictionary<OfferList> | null }>(),
);

export const fetchNextOffersError = createAction(`${KEY} next offer list error`, props<{ error: Error }>());

// UPDATE LOCAL STATE
export const addLocationFilterOption = createAction(`${KEY} add location filter option`, props<{ id: number }>());

export const removeLocationFilterOption = createAction(`${KEY} remove location filter option`, props<{ id: number }>());

export const addKindsFilterOption = createAction(`${KEY} add kind filter option`, props<{ id: number }>());

export const removeKindsFilterOption = createAction(`${KEY} remove kind filter option`, props<{ id: number }>());

export const addBoardsFilterOption = createAction(`${KEY} add board filter option`, props<{ id: number }>());

export const removeBoardsFilterOption = createAction(`${KEY} remove board filter option`, props<{ id: number }>());

export const addTagsFilterOption = createAction(`${KEY} add tag filter option`, props<{ id: number }>());

export const removeTagsFilterOption = createAction(`${KEY} remove tag filter option`, props<{ id: number }>());

export const addConveniencesFilterOption = createAction(
	`${KEY} add convenience filter option`,
	props<{ id: number }>(),
);

export const removeConveniencesFilterOption = createAction(
	`${KEY} remove convenience filter option`,
	props<{ id: number }>(),
);

export const addStopsFilterOption = createAction(`${KEY} add stops filter option`, props<{ id: number }>());

export const removeStopsFilterOption = createAction(`${KEY} remove stops filter option`, props<{ id: number }>());

export const updateOffersDateRange = createAction(
	`${KEY} update offers date range`,
	props<{ dateRange: OfferDateRange }>(),
);

export const updateAdultsCount = createAction(`${KEY} update adults count`, props<{ adults: number }>());

export const updateChildrenCount = createAction(`${KEY} update children count`, props<{ children: number }>());

export const updateNights = createAction(`${KEY} update nights`, props<{ row: RowValue }>());

export const updateNightsOptions = createAction(`${KEY} update nights options`, props<{ option: RowValue }>());

export const updateQueryParams = createAction(`${KEY} update queryParams`, props<{ params: string }>());

export const updateSelectedValues = createAction(`${KEY} update selected values`, props<{ selected: Selected }>());

export const updateOwnTransport = createAction(`${KEY} update own transport`, props<{ ownTransport: boolean }>());

export const updateSearchFilter = createAction(`${KEY} update search value`, props<{ search: string }>());

export const clearAvailableBoards = createAction(`${KEY} clear available boards`);

export const clearOfferList = createAction(`${KEY} clear offers list`);

export const clearBoardsFilter = createAction(`${KEY} clear filter option`, props<{ boardId: number }>());

export const clearConveniencesFilter = createAction(
	`${KEY} clear conveniences filter`,
	props<{ convenienceId: number }>(),
);

export const clearStopsFilter = createAction(`${KEY} clear stops filter`, props<{ stopId: number }>());

export const clearLocationsFilter = createAction(`${KEY} clear locations filter`, props<{ locationId: number }>());

export const clearKindsFilter = createAction(`${KEY} clear kinds filter`, props<{ kindId: number }>());

export const clearTagsFilter = createAction(`${KEY} clear tags filter`, props<{ tagId: number }>());

export const clearSearchFilter = createAction(`${KEY} clear search filter`);

export const clearSelectedOptions = createAction(`${KEY} clear selected options`);

export const setDefaultNights = createAction(`${KEY} set default nights`);

export const setDefaultAdultValue = createAction(`${KEY} set default adults value`);

export const setDefaultChildrenValue = createAction(`${KEY} set default children value`);

export const setInitialState = createAction(`${KEY} set initial state`);

export const clearCurrentOfferIndex = createAction(`${KEY} clear current offer index`);

export const clearOffersNextPage = createAction(`${KEY} clear offers next page`);

export const setSorting = createAction(`${KEY} set offers sorting`, props<{ sorting: keyof typeof SORTING_OPTIONS }>());

export const updateCount = createAction(`${KEY} update offers count`, props<{ count: number }>());

export const updateOffset = createAction(`${KEY} update offers offset`, props<{ offset: number | null }>());

export const updateNextOffers = createAction(
	`${KEY} update next page offer list`,
	props<{ nextPage: NumericDictionary<OfferList> | null }>(),
);

export const updateOffers = createAction(
	`${KEY} update offer list`,
	props<{ offersList: NumericDictionary<OfferList> }>(),
);

export const updateNextPageIsLoading = createAction(
	`${KEY} update next page is loading`,
	props<{ nextPageIsLoading: boolean }>(),
);
