import { normalizeStringToUrl } from '@/_utils/urls/normalize-string-to-url';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map, take } from 'rxjs';
import { DEFAULT_PAGINATION_LIMIT, DEFAULT_PAGINATION_OFFSET } from '@/_store/offers/offers.types';
import { createQueryParams } from '@/_store/offers/offers.utils';
import { OffersFacade } from '@/_store/offers/offers.facade';
import { Router } from '@angular/router';
import { MenuMultimedia } from '@/offer/offer.model';

export interface Position {
	id: number;
	name: string;
	link: string;
	childMenus?: Position[];
	isActive: boolean;
	anchor: string;
	multimedia: MenuMultimedia;
	order: number;
	textOverListing: string;
	textUnderListing: string;
}

@Injectable({
	providedIn: 'root',
})
export class NaviService {
	list = new BehaviorSubject<Position[]>([]);
	activeList$ = this.list.pipe(map((items) => this.filterActiveManuItems(items)));
	listMenuHoverState = false;
	private readonly offersFacade = inject(OffersFacade);
	private readonly router = inject(Router);
	private readonly urlParams$ = this.offersFacade.urlParams$;
	private readonly offset$ = this.offersFacade.offset$;

	displayMask(): void {
		this.listMenuHoverState = true;
	}

	hideMask(): void {
		this.listMenuHoverState = false;
	}

	moveToSubItem(subitem: Position, groupName: string): void {
		let link = subitem.link;

		if (subitem.link.includes('wakacje-autokarem') && subitem.name) {
			const name = normalizeStringToUrl(subitem.name);
			const group = normalizeStringToUrl(groupName);
			link = link.replace('wakacje-autokarem', `wakacje-autokarem/${group}/${name}`);
		}

		const index = link.indexOf('/wakacje-autokarem');

		if (index === -1) {
			window.location.href = link;
			return;
		}

		link = `${window.origin}${link.slice(index)}`;
		window.location.href = link;
	}

	search(searchPhrase: string): void {
		if (!searchPhrase.length) return;
		this.offersFacade.updateSearchFilter(searchPhrase);
		this.offersFacade.updateOffset(DEFAULT_PAGINATION_OFFSET);
		combineLatest([this.urlParams$, this.offset$])
			.pipe(take(1))
			.subscribe(([params, offset]) => {
				const queryParams = createQueryParams(params);
				this.offersFacade.clearOfferList();
				this.offersFacade.fetchOfferList(
					params + `&limit=${DEFAULT_PAGINATION_LIMIT}&offset=${offset}`,
					params + `&limit=${DEFAULT_PAGINATION_LIMIT}&offset=${offset + DEFAULT_PAGINATION_LIMIT}`,
				);
				this.router.navigate(['/wakacje-autokarem'], {
					queryParams,
				});
			});
	}

	private filterActiveManuItems(items: Position[]): Position[] {
		return items
			.filter((item) => item.isActive)
			.map((item) => ({
				...item,
				childMenus: item.childMenus ? this.filterActiveManuItems(item.childMenus) : [],
			}));
	}
}
